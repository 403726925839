@import "../../../data/styles.css";

li > a, .menu{
	font-size: 90%;
	text-decoration: none;
	color: var(--primary-color);
	font-weight:bolder;
}

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 360px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.nav-list {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	list-style: none;
	align-items: center;

}

.nav-item.active a {
	color: var(--link-color) !important;
}

.menu{
	display: none;
}
.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 600px) {
	.nav-container{
		justify-content: end;
		margin-top: 5%;
		margin-right: 5%;
	}

	.navbar{
		position: static;
	}

	.nav-background{
		width: 100px;
	}
	.menu{
		display: block;
	}

	li > a {
		display: none;
	}
}
