@import "../../../data/styles.css";

.project {
	border-radius: 20px;
	opacity: 0.8;
	height: 59vh;
}


.project-container{
	display: flex;
	flex-direction: column;
	justify-content:space-between ;
	min-height: 300px;
}

.project a {
	text-decoration: none;
}

.project:hover {
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-logo {
	width: 30px;
	margin-bottom: 1rem;
}

.project-logo img {
	width: 100%;

}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	margin-bottom: 1rem;
	font-weight: 700;
}

.project-description {
	margin-bottom: 1rem;
	max-height: 100px;
	color: var(--secondary-color);
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.project-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.project-link-text {
	padding-left: 20px;
	font-weight: 700;
}

@media (max-width: 600px) {
	.project-container{
		height: auto;
	}
	.project-link{
		margin-top: 5%;
	}

	.project{
		height: 100%;
	}
}
