.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

.all-projects-project {
	width: calc(100% / 3);
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

}

@media (max-width: 600px) {
	.all-projects-project {
		width: 100%;
		margin-top:5%;
	}
	.all-projects-container {
		display: block;
		width: 100%;
	}
}
